
import React from "react";
import { HeroAcercaDeMisCuotas } from "../components/HeroAcercaDeMisCuotas/HeroAcercaDeMisCuotas";


class ScrollToTopOnMount extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return null;
  }
}
export function AcercaDeMisCuotas () {
  return <>
  <ScrollToTopOnMount />

  <HeroAcercaDeMisCuotas/>
</>
}