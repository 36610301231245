import { SectionPasosWeb } from "../components/SectionPasosWeb/SectionPasosWeb"
import { SectionWebDetails } from "../components/SectionWebDetails/SectionWebDetails"
import React from "react";
class ScrollToTopOnMount extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return null;
  }
}
export function Web () {
  return <>
  <ScrollToTopOnMount />
    <section className="separador"></section>
    <SectionPasosWeb />
    <SectionWebDetails />
  </>
}