export function HeroAcercaDeMisCuotas () {
  return (
    <section className="hero-faqs" style={{background:'#F1F3F9'}}>
      <div className="container">
        <article className="justificarText">
          <h2 className="hero-faqs__title">Acerca de Mis Cuotas</h2>
          <p className="hero-faqs__subtitle"><b>MIS CUOTAS</b> te ofrece la posibilidad de acceder al producto que más quieres en el momento y lugar que desees. Estamos presentes en más de 40 puntos de ventas ubicados estratégicamente en los retails tanto en Lima y Provincias. </p>
          <p className="hero-faqs__subtitle">Nuestra plataforma de evaluación es totalmente digital, ágil y sencilla donde con tan solo tu documento de identidad, un celular y el pago de tu primera cuota accedes al financiamiento en cómodas cuotas quincenales.</p>
          <p className="hero-faqs__subtitle">Esta es nuestra principal ventaja competitiva que nos permite diferenciarnos de otras instituciones tradicionales.</p>
          <p className="hero-faqs__subtitle"><b>MIS CUOTAS</b> es un producto de Te Presto Servicios Financieros S.A.C., próximamente estaremos en Colombia, con el objetivo de seguir creciendo en la región.</p>
          
        </article>
        <aside >
          <img className="imgAcerca" src="img/preguntas_frecuentes/hero.png" alt="" />
        </aside>
      </div>
    </section>
  )
}