
import { SectionPagosPasos } from "../components/SectionPagosPasos/SectionPagosPasos"
import { SectionAgentesDetails } from "../components/SectionAgentesDetails/SectionAgentesDetails"
import React from "react";
class ScrollToTopOnMount extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return null;
  }
}
export function Agentes () {
  return <>
  <ScrollToTopOnMount />
    <SectionPagosPasos />
    <SectionAgentesDetails />
  </>
}