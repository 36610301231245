import { SectionTerminoCondiciones } from "../components/SectionTerminoCondiciones/SectionTerminoCondiciones";
import React from "react";
class ScrollToTopOnMount extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return null;
  }
}
export function TerminosCondiciones () {
  return <>
  <ScrollToTopOnMount />
    <section className="section section-terminoHero">
        <div className="container">
            <h2 className="title">TÉRMINOS Y CONDICIONES</h2>
        </div>
    </section>
    <SectionTerminoCondiciones></SectionTerminoCondiciones>
  </>
}