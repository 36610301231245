import { useState } from "react"

export function SectionPasosParaComercio () {
  const [tab, setTab] = useState(1)

  const handleSetTab = (tab) => {
    setTab(tab)
  }

  return (
    <section className="section section-pasos">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h2 className="title">
              Pasos para comprar con Mis Cuotas:
            </h2>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 ">
            <div className="container-tabs">
              <p className={`container-tabs__buttons ${tab === 1 && 'container-tabs__buttons--active'}`} onClick={() => handleSetTab(1)}>Paso 1</p>
              <p className={`container-tabs__buttons ${tab === 2 && 'container-tabs__buttons--active'}`} onClick={() => handleSetTab(2)}>Paso 2</p>
              <p className={`container-tabs__buttons ${tab === 3 && 'container-tabs__buttons--active'}`} onClick={() => handleSetTab(3)}>Paso 3</p>
              <div className="content-container-tabs">
                <div className={tab === 1 ? 'content content--active' : 'content'}>
                  <div className="content-tab-pasos">
                    <div className="tab-num"><span>1</span></div>
                    <div className="tab-description">
                      <h3 className="pasosTitulo">Registra tu comercio en este formulario.</h3>
                      <p>Si tu negocio es aprobado, te pediremos algunos documentos para comprobar la información.</p>
                    </div>
                    <div className="tab-img">
                      <img src="img/pasos/img-tab-111.png" />
                    </div>
                  </div>         
                </div>
                <div className={tab === 2 ? 'content content--active' : 'content'}>
                  <div className="content-tab-pasos">
                    <div className="tab-num"><span>2</span></div>
                    <div className="tab-description">
                      <h3 className="pasosTitulo">Al aprobar tu comercio,<br/> haremos todo el proceso de activación y<br/> personalización </h3>
                    </div>
                    <div className="tab-img">
                      <img src="img/pasos/img-tab-222.png"  />
                    </div>
                  </div>
                </div>
                <div className={tab === 3 ? 'content content--active' : 'content'}>
                  <div className="content-tab-pasos">
                    <div className="tab-num"><span>3</span></div>
                    <div className="tab-description">
                      <h3 className="pasosTitulo">Capacitaremos a tu equipo</h3>
                      <p>Y listo, incrementaremos las ventas y el ticket.</p>
                    </div>
                    <div className="tab-img">
                      <img src="img/pasos/img-tab-333.png"  />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}