import ReactOwlCarousel from "react-owl-carousel"

export function SectionTiendasProductos () {
  const options = {
    stagePadding: 1,
    loop:true,
    margin:15,
    nav:false,
    navText: ["<i class=\"fa fa-angle-left\" aria-hidden=\"true\"></i>","<i class=\"fa fa-angle-right\" aria-hidden=\"true\"></i>"],
    dots: true,
    autoplay:true,
    autoplayTimeout:3000,
    autoplayHoverPause:true,
    responsive:{
      0:{
        items:1
      },
      600:{
        items:2
      },
      1200:{
        items:5
      }
    }
  
  }

  return (
    <section className="section section-tiendas-productos ">
      <div className="container">
        <h2 className="title">Productos destacados</h2>
        <div className="row">
          <div className="col-md-12">
            <ReactOwlCarousel {...options} className="owl-carousel owl-theme">
              <div className="item-beneficio">
                <img className="icon" src="img/tiendas/DJI/DJI-Mini-3-pro.jpg" />
              </div>
              <div className="item-beneficio">
                <img className="icon" src="img/tiendas/HONOR/Honor-Magic4-Pro.jpg" />
              </div>
              <div className="item-beneficio">
                <img className="icon" src="img/tiendas/HUAWEI/Huawei-FreeBuds-4.jpg" />
              </div>
              <div className="item-beneficio">
                <img className="icon" src="img/tiendas/MI STORE/Mi-Electric-Scooter-3.jpg" />
              </div>
              <div className="item-beneficio">
                <img className="icon" src="img/tiendas/TEC STORE/HUAWEI-Mateview.jpg" />
              </div>
              <div className="item-beneficio">
                <img className="icon" src="img/tiendas/DJI/DJI-OM4-SE.jpg" />
              </div>
              <div className="item-beneficio">
                <img className="icon" src="img/tiendas/HONOR/Honor-X9.jpg" />
              </div>
              <div className="item-beneficio">
                <img className="icon" src="img/tiendas/HUAWEI/Huawei-P50-Pro.jpg" />
              </div>
              <div className="item-beneficio">
                <img className="icon" src="img/tiendas/MI STORE/Redmi-Note-11.jpg" />
              </div>
              <div className="item-beneficio">
                <img className="icon" src="img/tiendas/TEC STORE/Samsung-A03.jpg" />
              </div>
              <div className="item-beneficio">
                <img className="icon" src="img/tiendas/HONOR/Honor-Watch-GS3.jpg" />
              </div>
              <div className="item-beneficio">
                <img className="icon" src="img/tiendas/MI STORE/Xiaomi-12-pro.jpg" />
              </div>  
            </ReactOwlCarousel>
          </div>
        </div>
      </div>
    </section>
  )
}