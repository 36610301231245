import { RespuestaTerminosCondi } from "../RespuestaTerminosCondi/RespuestaTerminosCondi"

export function SectionPoliticaDePrivacidad (){

    return(
      <>
      <section className="section section-faqs">
      <div className="container">
          <article>
            <div className="faqs-content">
              <RespuestaTerminosCondi i={1} title={"Identidad y domicilio del Titular de Banco de Datos Personales o encargado del Tratamiento"}
                contenido={["TE PRESTO SERVICIOS FINANCIEROS S.A.C. identificada con RUC N° 20608863762 ubicada en Calle Chiclayo N° 562, Miraflores, Lima, Perú es el titular del banco de datos en el que se almacenarán los datos personales facilitados en la presente solicitud. ",
                            "Los datos recopilados se encuentran en el Banco de Datos Personales denominado “Clientes” y “Prospecto de Clientes” de la Empresa. Dichos datos se mantendrán almacenados mientras la Empresa lo requiera para prestar el servicio, sea necesario que sean almacenados por fines regulatorios, o hasta por un máximo de cinco (5) años. Una vez vencido el periodo establecido la información recopilada será eliminada, disociada o anonimizada, salvo surjan contingencias que ameriten mantener los datos en el sistema de la Empresa."]} />
              
              <RespuestaTerminosCondi i={2} title={"Finalidad"}
                contenido={["La Empresa facilita a los usuarios y/o visitantes el acceso a la Plataforma Digital de Evaluación Crediticia para ofrecerle el servicio de financiamiento de productos y servicios de sus locales afiliados, así como otros servicios y contenidos relacionados, de manera electrónica, rápida y segura, previa evaluación y aprobación de la Empresa. Asimismo, los usuarios podrán acceder a la Web para revisar y gestionar los pagos de las cuotas referidas al crédito aprobado por la Empresa. Para ello, la Empresa tratará los datos personales durante el proceso de revisión, evaluación, aprobación/rechazo del financiamiento y pago de crédito requeridos al usuario con la finalidad de gestionar el servicio de financiamiento y afines que prestará a su favor."
                           ]} 
                            />
              
              <RespuestaTerminosCondi i={3} title={"¿Qué información recopilamos?"}
                contenido={["La Plataforma Digital de Evaluación Crediticia incorpora un formulario de registro voluntario por medio del cual solicita al usuario datos personales que permiten identificarlo, contactarlo y acceder al servicio de financiamiento. Por su parte, el usuario podrá cancelar sus cuotas en la Web previo registro y aprobación de una cuenta y contraseña.",
                            "Los datos personales recopilados para acceder al financiamiento y realizar los pagos correspondientes son los siguientes: nombres, apellidos, documento de identidad, dirección, correo electrónico, clave, celular, ingresos mensuales, género, estado civil, número de personas que dependen del usuario, trabajo, profesión/ocupación, nombre de la empresa donde trabaja el usuario, RUC de la empresa, imagen (foto) del usuario, entre otros.",
                            "Se pone en conocimiento de los usuarios que los formularios, mediante los cuales otorguen sus datos personales, incluyen preguntas obligatorias y facultativas, las cuales podrán ser identificadas en cada formulario"]} />
              
              <RespuestaTerminosCondi i={4} title={"Autorización de uso de imagen"}
                contenido={["En virtud del presente documento, y de conformidad con lo dispuesto en la Ley 297333 y su Reglamento, el usuario toma conocimiento y acepta ceder los derechos de uso que goza sobre su propia imagen a la Empresa, con la finalidad de ser utilizados para validar la identidad del usuario con la foto que se registra en su Documento Nacional de Identidad.",
                            "En consecuencia, el usuario autoriza a la Empresa de forma voluntaria y gratuita, a fijar, reproducir, comunicar, transferir, modificar por medios técnicos conocidos en la actualidad y los que pudieran desarrollarse en el futuro, las fotografías tomadas en el marco del presente documento. Las fotografías podrán reproducirse en parte o enteramente en todo soporte (papel, magnético, digital, etc.) e integradas a cualquier otro material (fotografía, dibujo, ilustración, pintura, vídeo, animaciones, etc.), conocidos y por conocer.",
                            ]} />
              
              <RespuestaTerminosCondi i={5} title={'¿Cómo utiliza la Empresa los datos personales recopilados?'}
                contenido={["El usuario, mediante la marcación de la casilla correspondiente, acepta expresamente y de forma previa, libre e inequívoca que sus datos personales serán tratados y se mantendrán en el banco de datos personales de la Empresa, mientras se consideren útiles con el objetivo de que la Empresa pueda prestar los servicios financieros y otros fines, tales como:",
                            "■ Cumplir con la finalidad necesaria para evaluar las solicitudes requeridas.",
                            "■ Procesar las solicitudes, lo que incluye el envío de correos electrónicos y/u otros tipos de comunicaciones para informarle sobre el estado de su trámite.",
                            "■ Comunicarnos con el cliente en caso que haya algún problema o impedimento que eviten contratar los servicios prestados por la Empresa.",
                            "■ Prestar servicio de soporte al cliente, resolver problemas, gestionar suscripciones y responder a solicitudes, preguntas y comentarios.",
                            "■ Enviar comunicaciones comerciales a través de e-mail u otros medios de comunicación virtual para informarle de nuevos productos, ofertas especiales, publicidad y novedades relacionadas con la Plataforma Digital de Evaluación Crediticia y/o la Empresa en general.",
                            "■ Enviar comunicaciones comerciales a través de e-mail u otros medios de comunicación virtual para informarle de nuevos productos, ofertas especiales, publicidad y novedades relacionadas con la Plataforma Digital de Evaluación Crediticia y/o la Empresa en general.",
                            "■ Realizar estudios internos sobre los intereses, comportamientos y hábitos de conducta de los usuarios a fin poder ofrecerles un mejor servicio de acuerdo a sus necesidades, con información que pueda ser de su interés.",
                            "■ Ceder, transferir o comunicar sus datos personales a favor de cualquier entidad que, en atención a sus competencias, lo requiera; así como para efectos de auditoría interna.",
                            "■ Impedir, detectar, identificar, investigar y responder a posibles demandas, responsabilidades, comportamientos prohibidos y actividades delictivas.",
                            "■ Cumplir y aplicar los derechos legales, requisitos, acuerdos y directivas.",
                            "La información personal proporcionada será única y exclusivamente para procesar, evaluar y gestionar la solicitud presentada. En tal sentido, los datos personales nos permitirán identificar al remitente de la solicitud y, una vez evaluada su comunicación, dar respuesta a ésta. Asimismo, la Empresa podrá solicitarle mayor información en caso así se requiera.",
                            "Del mismo modo, los datos del usuario serán utilizados para el envío de comunicaciones comerciales de los establecimientos o comercios electrónicos afiliados a la Empresa.",
                            "De acuerdo a lo estipulado en el artículo 58° del Código de Protección y Defensa del Consumidor, el usuario que autorice a la Empresa permitirá que esta remita información al titular de los datos personales, sobre los productos que ofrece la Empresa, para lo cual se utilizará la vía postal, telefónica, correos electrónicos, medios electrónicos o cualquier otro medio de comunicación. Además, el usuario autoriza a la Empresa para que realice, por sus propios medios, o comparta, ceda o transfiera estos datos a terceros, a fin de realizar actividades de telemarketing mediante sistemas de llamado telefónico, envío de mensajes de texto a celular, correos electrónicos postulantes (individuales o masivos) o medio electrónicos, para promover productos; así como, mantener actualizados los datos de los titulares, bajo la garantía de que la Empresa procurará que estos no se vean afectados por cualquier uso indebido."]} />

              <RespuestaTerminosCondi i={6} title={'Transferencia de datos personales y destinatarios'}
                contenido={["Los datos personales que recopila la Empresa se podrían transferir a las diferentes entidades que mencionaremos a continuación, única y exclusivamente para cumplir con las finalidades expresamente señaladas en el presente documento.",
                            "Cualquier otra información que el usuario haya registrado en los sitios web de terceros, proveedores del producto o servicio objeto de contratación, permanecerá en el dominio de dicho proveedor, no siendo responsabilidad de la Empresa."
                            ]} />
                  
              <RespuestaTerminosCondi i={7} title={'Sobre la veracidad de la Información que recolectamos'}
                contenido={["El usuario que accede a la Plataforma Digital de Evaluación Crediticia y a la Web reconoce haber leído y que entiende el contenido de este documento. Por ello, el usuario al registrarse y utilizar la Plataforma Digital de Evaluación Crediticia y en la Web, declara que toda la información proporcionada es verdadera, actual, completa y exacta. Cada usuario es responsable por la veracidad, exactitud, vigencia y autenticidad de la información suministrada, y se compromete a mantenerla debidamente actualizada.",
                            "La Empresa se reserva el derecho de verificar la veracidad de los datos personales proporcionados por el usuario a través de información obtenida de fuentes de acceso público o entidades especializadas en la provisión de dicha información. Asimismo, la Empresa no se hace responsable de la veracidad de la información que no sea de elaboración propia, por lo que tampoco asume responsabilidad alguna por posibles daños o perjuicios que pudieran originarse por el uso de dicha información.",
                            ]} />

              <RespuestaTerminosCondi i={8} title={'¿Cómo resguardamos su información personal?'}
                contenido={["La Empresa, a través de la Plataforma Digital de Evaluación Crediticia y la Web, garantiza que ha adoptado las medidas legales, técnicas y organizativas necesarias para proteger la información de los usuarios y para mantener el nivel de seguridad requerido, en atención a la naturaleza de los datos personales tratados, cumpliendo con lo exigido en la Ley 297333 y su Reglamento.",
                            "La Empresa emplea diversas técnicas de seguridad para proteger tales datos de accesos no autorizados. Sin perjuicio de ello, la Empresa no se hace responsable por interceptaciones ilegales o violación de sus sistemas o bases de datos por parte de personas no autorizadas, así como la indebida utilización de la información obtenida por esos medios, o de cualquier intromisión ilegítima que escape al control de la Empresa y que no le sea imputable. Tampoco se hace responsable de posibles daños o perjuicios que se pudieran derivar de interferencias, omisiones, interrupciones, virus informáticos, averías telefónicas o desconexiones en el funcionamiento operativo de la Plataforma Digital de Evaluación Crediticia y de la Web, motivadas por causas ajenas a la Empresa; de retrasos o bloqueos en el uso de la Plataforma Digital de Evaluación Crediticia y de la Web causados por deficiencias o sobrecargas en el Centro de Procesos de Datos, en el sistema de Internet o en otros sistemas electrónicos."
                            ]} />

              <RespuestaTerminosCondi i={9} title={'¿Cuándo empleamos o divulgamos información?'}
                contenido={["Queda terminantemente prohibido que el usuario que presenta una solicitud para acceder al financiamiento de la Empresa, brinde información personal de otros usuarios, sin que medie su autorización expresa. La Empresa se compromete a no divulgar o compartir con terceros los datos personales recopilados de los usuarios sin que se haya prestado el debido consentimiento para ello, con excepción de los siguientes casos:",
                            "◉ Para cumplir la finalidad principal de los servicios brindados a través de la Plataforma Digital de Evaluación Crediticia y la Web.",
                            "◉ Cuando lo exija o lo permita la Ley y el cumplimiento de mandatos judiciales, administrativos, citaciones u órdenes emitidas por las autoridades.",
                            "◉ Para investigar o denunciar actividades ilícitas, o cuando sea razonablemente necesario.",
                            "◉ Para determinar el cumplimiento de las normas vigentes, incluida la prevención o investigación de delitos o faltas, por parte de alguna autoridad competente. ",
                            "◉ Para ejercer o responder a una acción legal.",
                            "◉ Para responder frente a terceros que puedan interceptar o acceder a información de manera indebida (vulneración del sistema), mediante intromisiones ilegítimas e ilegales fuera del control de la Empresa.",
                            ]} />
            
              <RespuestaTerminosCondi i={10} title={'Privacidad de menores de edad'}
                contenido={["Por su naturaleza, los productos y servicios ofrecidos por la Empresa no se utilizarán para supervisar la actividad, la ubicación física o uso realizado por menores de edad. Si el usuario ha identificado que en la Plataforma Digital de Evaluación Crediticia o en la Web han recopilado información de menores de edad, por error o por uso de ellos mismos, los padres y/o apoderados deberán ponerse en contacto con la Empresa a los correos en el presente documento.",
                            "La Empresa insta a los padres y/o apoderados a instruir a sus menores hijos para que nunca den sus nombres reales, dirección, número de teléfono, datos bancarios sin su permiso. Si han tomado conocimiento de que sus menores hijos han proporcionado a la Empresa, datos personales sin su consentimiento, envíenos una alerta poniéndose en contacto con nosotros como se indica más adelante. Si llega al conocimiento de la Empresa que la recopilación de datos personales de menores de edad rápidamente se procederá a eliminar dicha información y cancelar la cuenta del menor; caso contrario, la Empresa no se hace responsable por el ingreso de información que realicen los menores de edad.",
                            ]} />

              <RespuestaTerminosCondi i={11} title={'Cookies'}
                contenido={["Si navegas en la Plataforma Digital de Evaluación Crediticia y/o en la Web, estarás aceptando el uso de las cookies en las condiciones señaladas. No obstante, tienes la opción de deshabilitar y eliminar las cookies en el navegador. En caso de que un usuario bloquee las cookies en su navegador, puede seguir navegando por el sitio, pero es posible que algunos servicios no estén disponibles, y la experiencia de navegación puede verse limitada."]} />
              
              <RespuestaTerminosCondi i={12} title={'Derechos de acceso, rectificación, cancelación y oposición de datos personales'}
                contenido={["Los titulares de los datos personales, sus apoderados o representantes acreditados podrán en todo momento revocar el consentimiento otorgado expresamente a la Empresa, tanto como limitar el uso o divulgación de sus datos personales (respecto de los datos que no sean imprescindibles para la prestación del servicio).",
                            "Los titulares de los datos personales o sus apoderados podrán dirigir una solicitud a la siguiente dirección: Calle Schell N° 168 – 2do piso, distrito de Miraflores, provincia y departamento de Lima o enviar un correo electrónico: servicioalcliente@miscuotas.com; o al WhatsApp +51 982557122.",
                            "A fin de ejercer los derechos de acceso, rectificación, cancelación y oposición, los usuarios deberán presentar su solicitud en los términos que establece el Reglamento de la Ley N° 29733. De considerar que no ha sido atendido en el ejercicio de sus derechos puede presentar una reclamación ante la Autoridad Nacional de Protección de Datos Personales, dirigiéndose a la Mesa de Partes del Ministerio de Justicia y Derechos Humanos, ubicado en Calle Scipión N° 350, distrito de Miraflores, provincia y departamento de Lima, Perú.",
                            "Sin perjuicio de lo anterior, la Empresa podrá conservar determinada información personal del usuario que solicita la baja, a fin de que sirva de prueba ante una eventual reclamación contra la Empresa por responsabilidades derivadas del tratamiento de dicha información. Dicha conservación deberá realizarse previo bloqueo de los datos, de manera que se impida su habitual tratamiento y su duración no podrá ser superior al plazo de prescripción legal de dichas responsabilidades.",
                            "La Empresa les recuerda a los usuarios que las finalidades de tratamiento de datos personales necesarias para la ejecución de la relación contractual que vincula al usuario registrado y a la Empresa no requieren del consentimiento del mismo."]} />
              
              <RespuestaTerminosCondi i={13} title={'Modificaciones de las Políticas de Privacidad'}
                contenido={["La Empresa se reserva expresamente el derecho a modificar, actualizar o completar en cualquier momento la presente Política de Privacidad y Tratamiento de Datos Personales. Cualquier modificación, actualización o ampliación producida en la presente Política será inmediatamente publicada en la página web www.miscuotas.com, por lo que se recomienda al usuario revisarla periódicamente, especialmente antes de proporcionar información personal.",
                            "Este documento es parte integrante de los Términos y Condiciones publicados en la página web www.miscuotas.com, que el usuario acepta al momento de registrarse, y de manera obligatoria antes de solicitar los servicios ofrecidos por la Empresa."
                            ]} />
            </div>
          </article>
        </div>
      </section>
        </>
    )
}