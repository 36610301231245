import { useState } from "react"

export function RespuestaTerminosCondi({i,title,contenido}){

  const [active, setActive] = useState(false)
  
  const handleClick = () => {
    setActive(!active)
  }
    return(
        <details className="masDetalle" onClick={handleClick}>
                <summary>
                  <p><span>{i}.</span> {title} </p>
                </summary>
                <div className="justificarText">
                  <p>{contenido[0]}</p>
                  <p>{contenido[1]}</p>
                  <p>{contenido[2]}</p> 
                  <p>{contenido[3]}</p>
                  <p>{contenido[4]}</p>
                  <p>{contenido[5]}</p>
                  <p>{contenido[6]}</p>
                  <p>{contenido[7]}</p>
                  <p>{contenido[8]}</p>
                  <p>{contenido[9]}</p>
                  <p>{contenido[10]}</p>
                  <p>{contenido[11]}</p> 
                  <p>{contenido[12]}</p>
                  <p>{contenido[13]}</p>
                  <p>{contenido[14]}</p>
                  <p>{contenido[15]}</p>
                  <p>{contenido[16]}</p>
                  <p>{contenido[17]}</p>
                </div>
              </details>

    )
}