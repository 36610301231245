import { Link } from "wouter"

export function SectionSectionPagosHero () {
    return (
      <section className="section section-pagoshero">
      <div className="container">
        <h2 className="title">Canales de Pago</h2>
        <p>Para ti, que quieres pagar las cuotas de tu crédito de forma rápida y segura, ponemos a tu disposición los siguientes canales:</p>
        <article className="pagos-canales canalespagos-cards">
          {/* <div className="canalespagos-card">
            <h3>En los bancos</h3>
            <img src="img/pagos/canales-1.png" alt="" />
            <Link to="/bancos">Más información</Link>  
          </div> */}
          <div className="canalespagos-card">
            <h3>En nuestra página web</h3>
            <img src="img/pagos/canales-2.png" alt="" />
            <Link to="/web">Más información</Link>
          </div>
          {/* <div className="canalespagos-card">
            <h3>En los agentes  </h3>
            <img src="img/pagos/canales-3.png" alt="" />
            <Link to="/agentes">Más información</Link>
          </div> */}
          <div className="canalespagos-card">
            <h3>En las tiendas afiliadas</h3>
            <img src="img/pagos/canales-4.png" alt="" />
            <Link to="/tiendas">Más información</Link>
          </div>
        </article>
      </div>
    </section>
        )
    }