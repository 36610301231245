import { Link } from "wouter";

export function SectionCanales () {
  return (
    <section className="section section-canales">
      <div className="container">
        <h2 className="title">Canales de pago</h2>
        <article className="canales-cards">
          <div className="canales-card">
            <img src="img/preguntas_frecuentes/canales-1.png" alt="" />
            <h3>Comercios Afiliados <br /> a Mis Cuotas</h3>
            <Link to="/tiendas">Ver más <i className="fas fa-arrow-right"></i></Link>
          </div>
          {/* <div className="canales-card">
            <img src="img/preguntas_frecuentes/canales-2.png" alt="" />
            <h3>Cómo comprar con <br /> Mis Cuotas</h3>
            <Link to="/bancos">Ver más <i className="fas fa-arrow-right"></i></Link>
          </div> */}
          {/* <div className="canales-card">
            <img src="img/preguntas_frecuentes/canales-3.png" alt="" />
            <h3>Cómo pagar <br /> Mis Cuotas</h3>
            <Link to="/agentes">Ver más <i className="fas fa-arrow-right"></i></Link>
          </div> */}
          <div className="canales-card">
            <img src="img/preguntas_frecuentes/canales-4.png" alt="" />
            <h3>Mis Cuotas por <br /> Internet</h3>
            <Link to="/web">Ver más <i className="fas fa-arrow-right"></i></Link>
          </div>
        </article>
      </div>
    </section>
  )
}