export function SectionBeneficios () {
  return (
    <section className="section section-beneficios">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="title-section-beneficios">
              <h1>Compra en Mis Cuotas</h1>
              Compra hoy y paga después, es<br /><span>100% digital, 100% rápido y 100% fácil.</span>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3 col-6">
            <div className="item-beneficio">
              <img className="icon" src="img/beneficios/icon-1.png" />
                <p className="title-item">Sin<br />intereses</p>
            </div>
          </div>
          <div className="col-md-3 col-6">
            <div className="item-beneficio">
              <img className="icon" src="img/beneficios/icon-2.png" />
                <p className="title-item">Sin<br />historial crediticio</p>
            </div>
          </div>
          <div className="col-md-3 col-6">
            <div className="item-beneficio">
              <img className="icon" src="img/beneficios/icon-3.png" />
                <p className="title-item">Sin<br />papeleo complicado</p>
            </div>
          </div>
          <div className="col-md-3 col-6">
            <div className="item-beneficio">
              <img className="icon" src="img/beneficios/icon-4.png" />
                <p className="title-item">Aprobación<br />instantánea</p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 note">
            <img src="img/beneficios/foco.png" /><br />
              <span>
                Sólo necesitas tu <b>DNI, correo o  celular.</b><br />
                  *El 0% de interés aplica para compras en 2 cuotas hasta por S/ 2,000
              </span>
            </div>
          </div>
        </div>
    </section>
  )
}