import { Respuesta } from "../Respuesta/Respuesta"

export function RespuestasSegundo () {

  return <>
    <Respuesta i={1} title="¿Qué es la TCEA?">
      <p>La TCEA (Tasa de Costo Efectivo Anual) es un porcentaje que mide el coste efectivo de un producto financiero. </p>
      <p>Su cálculo incluye los intereses, comisiones y gastos que se aplica a un crédito. Se calcula de forma anual.</p>
    </Respuesta>
    <Respuesta i={2} title="¿Qué son los intereses?">
      <p>Los intereses son los costos derivados de recibir el préstamo. Esta cantidad es lo que te costará financiar tu compra.</p>
    </Respuesta>
    <Respuesta i={3} title="¿Cuál es la tasa de interés para mis compras con Mis Cuotas?">
        <p>Con Mis Cuotas puedes comprar a cuotas con 0% de interés! Si a tu compra aplican intereses, puedes consultar las tasas vigentes en el siguiente enlace.</p>
    </Respuesta>
    <Respuesta i={4} title="¿Qué es la cuota inicial?">
      <p>La cuota inicial es el pago que realizas al momento en que solicitas el préstamo con Mis Cuotas. </p>
      <p>El pago realizado por este concepto es deducido del importe total del crédito.</p>
    </Respuesta>
    <Respuesta i={5} title="¿Qué es el préstamo?">
      <p>Es el monto que se aprobó en tu solicitud del préstamo. </p>
      <p>Sobre esta cantidad se calcula el monto de cada cuota, que variará según el número de cuotas.</p>
    </Respuesta>
    <Respuesta i={6} title="¿Cuántas cuotas tiene mi compra con Mis Cuotas?">
      <p>Mis Cuotas ofrece préstamos que alcanzan hasta 9 cuotas quincenales. </p>
      <p>Dependiendo del perfil del cliente, el sistema propondrá un plazo mínimo de financiamiento, esta información será devuelta por el sistema después de validar la identidad y no en todos los casos se mostrarán todas las opciones.</p>
    </Respuesta>
    <Respuesta i={7} title="¿Puedo elegir el número de cuotas? ">
      <p>Podrás elegir el número de cuotas dependiendo de la evaluación que realiza el sistema. </p>
      <p>No todos los casos tendrán la opción de elegir el número de cuotas de su préstamo.</p>
    </Respuesta>
    <Respuesta i={8} title="¿Por qué mi compra con Mis Cuotas no fue aprobada?">
      <p>Lamentamos que no hayas podido usar Mis Cuotas para finalizar tu compra. Esto se puede deber a tu perfil crediticio o algún problema durante la verificación de tu identidad.</p>
      <p>Esperamos más adelante poder financiar tus compras.</p>
    </Respuesta>
    <Respuesta i={9} title="¿Puedo solicitar nuevas financiaciones?">
      <p>Actualmente ello no es posible. Podrás volver a comprar con Mis Cuotas una vez hayas cancelado tu préstamo vigente. </p>
    </Respuesta>
  </>
}