
import React from "react";
import { SectionTasaIntereses } from "../components/SectionTasaIntereses/SectionTasaIntereses";
class ScrollToTopOnMount extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return null;
  }
}
export function TasasIntereses () {
  return <>
  <ScrollToTopOnMount />
  <section className="section section-terminoHero">
      <div className="container">
          <h2 className="title">TASAS Y TARIFAS</h2>
          <p>A continuación encontrarás el detalle de las tasas vigentes</p>
      </div>
  </section>
  <SectionTasaIntereses/>

</>
}