export function TiendaHero () {
  return (
    <section className="section section-tienda">
      <div className="container">
        <article className="hero-tienda">
          <h4>Descubre todas las tiendas donde puedes comprar hoy y pagar después en cuotas.</h4>
          <p>Encuentra tu producto favorito y sé libre para pagarlo como quieras.</p>
          <img src="img/tiendas/hero.png" alt="" />
        </article>
        <aside className="aside-tienda">
          <img src="img/tiendas/aside.png" alt="" />
        </aside>
      </div>
    </section>
  )
}