import { SectionConsultas } from "../components/SectionConsultas/SectionConsultas"
import { SectionSectionPagosHero } from "../components/SectionSectionPagosHero/SectionSectionPagosHero"
import { SectionSelectorCanales } from "../components/SectionSelectorCanales/SectionSelectorCanales"
import { HeroPagos } from "../components/HeroPagos/HeroPagos"
import React from "react";
class ScrollToTopOnMount extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return null;
  }
}

export function Pagos () {
  return <>
  <ScrollToTopOnMount />
    <HeroPagos />
    <SectionSectionPagosHero />
    <main>
      <SectionSelectorCanales/>
      <SectionConsultas />
    </main>
  </>
}