

export function Wahtsap() {
  return (
    <>
    <div className="wp">
            <a target="_blank" href="https://wa.link/bqivy5">
            <img src="img/whatsapp.png" alt="whatsapp"/>
            </a>
            <br/>
        </div> 
    </>
  )
}