export function HeroAyuda () {
  return (
    <section className="hero-faqs">
      <div className="container">
        <article>
          <h2 className="hero-faqs__title">¿Necesitas ayuda?</h2>
          <p className="hero-faqs__subtitle">Encuentra todas las consultas que necesites y absuelve las dudas de manera inmediata.</p>
          <form className="hero-faqs__form">
            <input type="text" placeholder="Buscar consultas" />
            <button type="submit">
              <img src="img/preguntas_frecuentes/icon-search.png" alt="" />
            </button>
          </form>
        </article>
        <aside>
          <img src="img/preguntas_frecuentes/hero.png" alt="" />
        </aside>
      </div>
    </section>
  )
}