import { SectionPasosBancos } from "../components/SectionPasosBancos/SectionPasosBancos"
import { SectionBancosDetails } from "../components/SectionBancosDetails/SectionBancosDetails"
import { SectionBancos } from "../components/SectionBancos/SectionBancos"
import React from "react";
class ScrollToTopOnMount extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return null;
  }
}

export function Bancos () {
  return (
    <>
    <ScrollToTopOnMount />
      <main>
        <SectionBancos />
        <SectionPasosBancos />
        <SectionBancosDetails />
      </main>
    </>
  )
}