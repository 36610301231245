import { Link } from "wouter";

export function Footer() {
  return (
    <footer>
      <div className="footer-menu">
        <div className="container">
          <div className="row">
            <div className="col-md-4 widget-footer">
              <p className="textoFooter">Clientes</p>
              <a href="https://msextranet.web.app/auth/login" target="_blank">Pagar mi cuota</a>
              <a href="https://msextranet.web.app/auth/login" target="_blank">Ingresa a tu cuenta</a>
              <Link to="/comoFunciona">Cómo funciona Mis Cuotas</Link>
              <Link to="/ayuda">Ayuda</Link>
            </div>
            <div className="col-md-4 widget-footer">
              <p className="textoFooter">Comercios aliados</p>
              <Link to="/paraComercios">Únete a Mis Cuotas</Link>
              <a >Contacto</a>
            </div>
            <div className="col-md-4 widget-footer">
              <p className="textoFooter">Información de interés</p>
              <Link to="/terminos-y-condiciones">Términos y condiciones</Link>
              <Link to="/politicaPrivacidad">Política de privacidad</Link>
              <Link to="/tratamiento-de-datos">Tratamiento de datos</Link>
              <Link to="/tasas-y-intereses">Tasas y tarifas</Link>
              <Link to="/acerca-de-mis-cuotas" >Acerca de Mis Cuotas</Link>
              <a href="/libro-de-reclamaciones" ><img src="img/logo-libro-2.png" width="150" alt=""/></a>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-contact">
        <div className="container">
          <div className="row">
            <div className="col-md-4 widget-footer">
              <a ><img src="img/logo-blanco.png" alt=""/></a>
            </div>
            <div className="col-md-4 widget-footer">
              <p className="textoFooter">Contáctanos</p>
              <span><i className="fas fa-mobile-alt"></i>+51 982 557 122</span>
              <span><i className="far fa-envelope-open"></i>servicioalcliente@miscuotas.com</span>
            </div>
            <div className="col-md-4 widget-footer">
              <p className="textoFooter">Ubícanos</p>
              <span><i className="fas fa-map-marker-alt"></i>En Comercio Afiliados</span>
              <span><i className="far fa-calendar-alt"></i>Lun – Dom de 10 am a 10 pm</span>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-copyright">
        <div className="container">
          <div className="row">
            <div className="col-md-8 fc-text">
              Derechos Reservados © {(new Date().getFullYear())} Mis Cuotas, Te Presto Servicios Financieros SAC.
            </div>
            <div className="col-md-4 fc-social">
              <div className="social-media">
                <a href="https://web.facebook.com/Mis-Cuotas-Per%C3%BA-106143618935404" target="_blank"><span className="fab fa-facebook-f"> </span></a>
                <a  target="_blank"><span className="fab fa-linkedin-in"></span></a>
                <a  target="_blank"><span className="fab fa-twitter"></span></a>
                <a  target="_blank"><span className="fab fa-instagram"></span></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}