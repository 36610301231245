import { Route } from "wouter"

import { Header } from "./components/Header/Header"
import { Footer } from "./components/Footer/Footer"

import { Home } from "./pages/Home"
import { ComoFunciona } from "./pages/ComoFunciona"
import { Tiendas } from "./pages/Tiendas"
import { ParaComercios } from "./pages/ParaComercios"
import { Ayuda } from "./pages/Ayuda"
import { Pagos } from "./pages/Pagos"
import { Bancos } from "./pages/Bancos"
import { Web } from "./pages/Web"
import { Agentes } from "./pages/Agentes"
import { PagosTiendas } from "./pages/PagosTiendas"
import { TerminosCondiciones } from "./pages/TerminosCondiciones"
import { PoliticaPrivacidad } from "./pages/PoliticaPrivacidad"

import {HashRouter} from "react-router-dom"
import { TasasIntereses } from "./pages/TasasIntereses"
import { LibroReclamaciones } from "./pages/LibroReclamaciones"
import { AcercaDeMisCuotas } from "./pages/AcercaDeMisCuotas"
import { TratamientoDeDatos } from "./pages/TratamientoDeDatos"
import { Wahtsap } from "./components/Wahtsap/Wahtsap"

import { MiCuentaLogin } from "./pages/MiCuentaLogin"

function App() {

  return <>
    <Header />
    <HashRouter>
    <Route  path="/" component={Home}/>
    <Route  path="/comofunciona" component={ComoFunciona}/>
    <Route  path="/dondeComprar" component={Tiendas}/>
    <Route  path="/paraComercios" component={ParaComercios}/>
    <Route  path="/ayuda" component={Ayuda}/>
    <Route  path="/pagos" component={Pagos}/>
    <Route  path="/bancos" component={Bancos}/>
    <Route  path="/web" component={Web}/>
    <Route  path="/agentes" component={Agentes}/>
    <Route  path="/tiendas" component={PagosTiendas}/>
    <Route  path="/terminos-y-condiciones" component={TerminosCondiciones}/>
    <Route  path="/politicaPrivacidad" component={PoliticaPrivacidad}/>
    <Route  path="/tasas-y-intereses" component={TasasIntereses}/>
    <Route  path="/libro-de-reclamaciones" component={LibroReclamaciones}/>
    <Route  path="/acerca-de-mis-cuotas" component={AcercaDeMisCuotas}/>
    <Route  path="/tratamiento-de-datos" component={TratamientoDeDatos}/>

    <Route  path="/micuenta" component={MiCuentaLogin}/>

    </HashRouter>
    <Wahtsap/>
    <Footer />
  </>
}

export default App
