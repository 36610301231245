import { useState } from "react"

export function SectionPasosBancos () {
  const [tab, setTab] = useState(1)

  const handleSetTab = (tab) => {
    setTab(tab)
  }

  return (
    <section className="section section-pasos">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h2 className="title">
              Pasos para pagar en nuestras cuentas de Bancarias
            </h2>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 ">
            <div className="container-tabs">
              <p className={`container-tabs__buttons ${tab === 1 && 'container-tabs__buttons--active'}`} onClick={() => handleSetTab(1)}>Paso 1</p>
              <p className={`container-tabs__buttons ${tab === 2 && 'container-tabs__buttons--active'}`} onClick={() => handleSetTab(2)}>Paso 2</p>
              <p className={`container-tabs__buttons ${tab === 3 && 'container-tabs__buttons--active'}`} onClick={() => handleSetTab(3)}>Paso 3</p>
              <div className="content-container-tabs">
                <div className={tab === 1 ? 'content content--active' : 'content'}>
                  <div className="content-tab-pasos">
                    <div className="tab-num"><span>1</span></div>
                    <div className="tab-description">
                    
                      <h3 align="center">Ingresa al app o web de tu banco y realiza la transferencia a nuestra cuenta de Te Presto Servicios Financieros.
                    También puedes acercarte a la agencia más cercana de los bancos BCP, BBVA e Interbank.</h3>
                    </div>
                    <div className="tab-img">
                      <img src="img/bancos/paso-1.png" />
                    </div>
                  </div>
                </div>
                <div className={tab === 2 ? 'content content--active' : 'content'}>
                  <div className="content-tab-pasos">
                    <div className="tab-num"><span>2</span></div>
                    <div className="tab-description">
                      <h3 align="center">Transfiere o deposita <br /> el monto de tu cuota en nuestras cuentas.</h3>
                    </div>
                    <div className="tab-img">
                      <img src="img/bancos/paso-2.png" />
                    </div>
                  </div>
                </div>
                <div className={tab === 3 ? 'content content--active' : 'content'}>
                  <div className="content-tab-pasos">
                    <div className="tab-num"><span>3</span></div>
                    <div className="tab-description">
                    <h3 align="center">Tómale foto o escanea tu voucher y regístralo en nuestra web indicando el nro. de operación y DNI.</h3>
                    </div>
                    <div className="tab-img">
                      <img src="img/bancos/paso-3.png" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}