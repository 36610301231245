export function HeroPagos () {
  return (
    <section className="hero-pagos">
      <div className="container">
        <article>
          <h2 className="hero-faqs__title">Conoce nuestros</h2>
          <p className="hero-faqs__subtitle">nuevos métodos de pago</p>
          <img src="img/pagos/BANNER-3_yape.png" alt="" id="hero-yape"/>
          <img src="img/pagos/BANNER-3_tarjetas.png" alt="" id="hero-tarjeta"/>
          <form className="hero-faqs__form">
            <a id="boton-pagar" href="https://msextranet.web.app/auth/login" className="btn-primary">Pagar</a>
          </form>
        </article>
          <img src="img/pagos/BANNER-3_persona.png" id="hero-persona" alt=""/>
      </div>
    </section>
  )
}