import { SectionPasosParaComercio } from "../components/SectionPasosParaComercio/SectionPasosParaComercio"
import { SectionBeneficiosParaComercios } from "../components/SectionBeneficiosParaComercios/SectionBeneficiosParaComercios"
import { HeroComercios } from "../components/HeroComercios/HeroComercios"
import { SectionIntereses } from "../components/SectionIntereses/SectionIntereses"
import React from "react";
class ScrollToTopOnMount extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return null;
  }
}

export function ParaComercios () {
  

  return <>
  <ScrollToTopOnMount />
    <HeroComercios />
    <main>
      <SectionBeneficiosParaComercios />
      <SectionPasosParaComercio />
      <SectionIntereses />
    </main>
  </>
}