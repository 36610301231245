import { useEffect, useState } from "react"

export function SectionIntereses (){

  const [cuartonumero,setNumero4] =useState("");
  const [quintonumero,setNumero5] =useState("");
  const [sextonumero,setNumero6] =useState("");
  function aumentarNumero(cantidad,enviar){
    let cantidad1 =0
    let tiempo=setInterval(() => {
      cantidad1+=1;
      enviar(cantidad1)
      if (cantidad1 ===cantidad)
      {
        clearInterval(tiempo)
      }
    },50);
  }
  useEffect(() => {
    aumentarNumero(10,setNumero4);
    aumentarNumero(9,setNumero5);
    aumentarNumero(2,setNumero6);
  }, [])
    return(
        <section className="section section-intereses">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2 className="title">Así es cómo funciona Mis Cuotas para <br /> EL COMERCIO ALIADO: </h2>
            </div>
          </div>
          <div className="intereses-cards">
            <div className="intereses-card">
              <img src="img/para_comercios/intereses-1.png" alt="" />
              <h2>{cuartonumero}%</h2>
              <h3>Integración <br /> fácil y rápida</h3>
              <p>
                Nuestros comercios reportan un aumento de la conversión del 15% con mayor recurrencia de compra.
              </p>
            </div>
            <div className="intereses-card">
              <img src="img/para_comercios/intereses-2.png" alt="" />
              <h2>0{quintonumero}%</h2>
              <h3>Pago por <br /> adelantado</h3>
              <p>
                Mis Cuotas permite comprar aquello que quieres, cuando quieres, aumentando el ticket medio hasta en 40%.
              </p>
            </div>
            <div className="intereses-card">
              <img src="img/para_comercios/intereses-3.png" alt="" />
              <h2>0{sextonumero}%</h2>
              <h3>Sin <br /> riesgos</h3>
              <p>
                Los comercios informan que hasta el 30% de los clientes de Mis Cuotas son nuevos en su negocio
              </p>
            </div>
          </div>
        </div>
      </section>
    )
}