import ReactOwlCarousel from "react-owl-carousel"

export function SliderComoFunciona () {
  const options = {
    stagePadding: 1,
    loop:false,
    rewind: true,			
    margin:0,
    nav:false,
    navText: ["<i class=\"fa fa-angle-left\" aria-hidden=\"true\"></i>","<i class=\"fa fa-angle-right\" aria-hidden=\"true\"></i>"],
    dots: false,
    autoplay:true,
    autoplayTimeout:3000,
    autoplayHoverPause:true,
    animateIn: 'fadeIn',
    animateOut: 'fadeOut',
    responsive:{
      0:{
        items:1
      },
      600:{
        items:1
      },
      1200:{
        items:1
      }
    }
  
  }

  return (
    <section className="banner-desktop-funciona">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="comoFuncionaBanner">
              <ReactOwlCarousel {...options} className="owl-carousel owl-theme ">
                <div className="item-bannerFuncion">
                  {/* <img src="img/como_funciona/banner1.jpg" style={{width: '100%', display: 'block'}} /> */}
                </div>
                <div className="item-bannerFuncion">
                  {/* <img src="img/como_funciona/banner2.jpg" style={{width: '100%', display: 'block'}} /> */}
                </div>
              </ReactOwlCarousel>
            </div>
            <div className="text-bannerFuncion">
              <span className="text-banner">Disfrútalo ahora. Compras en</span>
              <p className="text-banner">Cuotas,desde 0%</p>
              <p className="text-banner">de interés.</p>
              <a id="bg-1-btn" href="/dondeComprar" className="btn-primary">Dónde comprar</a>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}