import { useState } from "react"
import { RespuestaPagos } from "../RespuestaPagos/RespuestaPagos"
export function SectionSelectorCanales (){

  const FAQS = {
    bancos: 'bancos',
    web: 'web',
    agentes: 'agentes',
    tiendas: 'tiendas',
  }
  const [query, setQuery] = useState(FAQS.bancos)

  const handleQuery = (query) => {
    setQuery(query)
  }
    // return(
    //     <div>
    //         <section className="section section-selector-canales">
    //     <div className="container">
    //       <h2 className="title">Canales de Pago de mis cuotas</h2>
    //       <div className="canales-selectores">
    //         {/* <button type="button" className={query === FAQS.bancos ? 'active' : ''} onClick={ () => handleQuery(FAQS.bancos)}>
    //           <img src="img/pagos/bancosWhite.png" alt="" className={query === FAQS.bancos ? 'imgCanales' : ''} />
    //           <span>Bancos</span>
    //         </button> */}
    //         <button type="button" className={query === FAQS.web ? 'active' : ''} onClick={ () => handleQuery(FAQS.web)}>
    //           <img src="img/pagos/webWhite.png" alt="" className={query === FAQS.web ? 'imgCanales' : ''}/>
    //           <span>Web</span>
    //         </button>
    //         {/* <button type="button" className={query === FAQS.agentes ? 'active' : ''} onClick={ () => handleQuery(FAQS.agentes)}>
    //           <img src="img/pagos/agentesWhite.png" alt="" className={query === FAQS.agentes ? 'imgCanales' : ''} />
    //           <span>Agentes</span>
    //         </button> */}
    //         <button type="button" className={query === FAQS.tiendas ? 'active' : ''} onClick={ () => handleQuery(FAQS.tiendas)}>
    //           <img src="img/pagos/tiendasWhite.png" alt="" className={query === FAQS.tiendas ? 'imgCanales' : ''}  />
    //           <span>Tiendas</span>
    //         </button>
    //       </div>
    //     </div>
    //   </section>
    //   <section className="section section-faqs">
    //         {/* { query === FAQS.bancos && <RespuestaPagos />} */}
    //         { query === FAQS.web && <RespuestaPagos />}
    //         {/* { query === FAQS.agentes && <RespuestaPagos />} */}
    //         { query === FAQS.tiendas && <RespuestaPagos />}
    //   </section>
    //     </div>
    // )
}