import { SectionPoliticaDePrivacidad } from "../components/SectionPoliticaDePrivacidad/SectionPoliticaDePrivacidad";

import React from "react";
class ScrollToTopOnMount extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return null;
  }
}

export function PoliticaPrivacidad () {
  return <>
  <ScrollToTopOnMount />
   <section className="section section-terminoHero">
        <div className="container">
            <h2 className="title">POLÍTICA DE PRIVACIDAD</h2>
        </div>
    </section>
    <SectionPoliticaDePrivacidad></SectionPoliticaDePrivacidad>
  </>
}