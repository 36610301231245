
import React from "react";

import { SectionMiCuentaLogin } from "../components/SectionMiCuentaLogin/SectionMiCuentaLogin"


class ScrollToTopOnMount extends React.Component 
{
    componentDidMount() 
    {
        window.scrollTo( 0, 0 );
    }

    render() 
    {
        return null;
    }
}
export function MiCuentaLogin () 
{
    return <>
        <ScrollToTopOnMount />
        <SectionMiCuentaLogin />
    </>
}