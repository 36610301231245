import { Respuesta } from "../Respuesta/Respuesta"

export function RespuestasTercero () {

  return <>
    <Respuesta i={1} title="¿Voy a recibir recordatorios relacionados con el pago de mis cuotas?">
      <p>Sí, lo haremos mediante tu correo electrónico y/o teléfono móvil. Te avisaremos unos días antes del vencimiento de tu cuota; y en caso no hayas podido realizar el pago puntualmente, enviaremos recordatorios para que puedas cumplir con este pago.</p>
    </Respuesta>
    <Respuesta i={2} title="¿Cómo pago las cuotas de Mis Cuotas?">
      <p>El pago de las cuotas las puedes realizar mediante transferencias, en los comercios afiliados o a través de nuestra web www.miscuotas.com. Para mayor información puedes consultar en www.miscuotas.com/pagos.	Una vez realizado ello tienes que adjuntar una copia del voucher emitido.</p>
    </Respuesta>
    <Respuesta i={3} title="¿Qué pasa si no pago mi cuota a tiempo?">
        <p>Al primer día de vencido el préstamo,  inicia el cobro de penalidades. Se cobrarán todos los días que permanezca en estado de mora el pago de la cuota o cuotas del préstamo.</p>
    </Respuesta>
    <Respuesta i={4} title="¿Cuál es el importe de la penalización diaria por impago?">
      <p>Se cargará una penalización diaria según el monto que aparece en la Hoja Resumen que se emitió al momento de realizar tu compra.</p>
    </Respuesta>
    <Respuesta i={5} title="¿Cómo hago para saber si el pago de mi cuota fue recibido por Mis Cuotas?">
      <p>Puedes ver el estado de tus cuotas en tu cuenta , ingresa aquí.</p>
    </Respuesta>
    <Respuesta i={6} title="¿Cómo puedo prepagar un crédito que tengo con Mis Cuotas?">
      <p>Para prepagar tu crédito, acércate a nuestra Oficina Principal en Calle Schell N° 168 – 2do. Piso, Miraflores, Lima de lunes a viernes desde las 9 am hasta las 6 pm.</p>
    </Respuesta>
  </>
}