
import { useState,useEffect } from "react";

export function HeroComercios () {

  const [primernumero,setNumero] =useState("");
  const [segundonumero,setNumero2] =useState("");
  const [terceronumero,setNumero3] =useState("");
  
  function aumentarNumero(cantidad,enviar){
    let cantidad1 =0
    let tiempo=setInterval(() => {
      cantidad1+=1;
      enviar(cantidad1)
      if (cantidad1 ===cantidad)
      {
        clearInterval(tiempo)
      }
    },50);
  }
  useEffect(() => {
    aumentarNumero(15,setNumero)
    aumentarNumero(80,setNumero2)
    aumentarNumero(3,setNumero3)
  }, [])
  

  return ( 
    <section className="hero-comercios">
      <div className="container">
        <div className="col-md-12">
          <h2 className="title text-center">Aumenta tus ventas con Mis Cuotas</h2>
        </div>
        <div className="cards">
          <div className="comercios-card">
            <div className="tarjeta">
              <div className="adelante">
                  <h3 className="comercios-card__title">Aumenta tus <br /> ventas</h3>
                  <p className="comercios-card__description">Nuestros comercios aliados han reportado un aumento de la conversión del 15% y una mayor recurrencia de compra.</p>
                  <hr />
                  <div className="subtitle__image">
                    <h3 className="comercios-card__subtitle">
                      Hasta <span>{primernumero}%</span>
                    </h3>
                    <img src="img/para_comercios/card-1.png" alt="" />
                  </div>
                </div>
                <div className="comercios-card__info atras">
                  <h3 className="comercios-card__title">Aumenta tus <br /> ventas</h3>
                  <p>Nuestros comercios aliados han reportado un aumento de la conversión del 15% y una mayor recurrencia de compra.</p>
                  <hr />
                </div>
            </div>
          </div>
          <div className="comercios-card">
            <div className="tarjeta">
                <div className="adelante">
            <h3 className="comercios-card__title">Incrementa tu Ticket <br /> Promedio</h3>
            <p className="comercios-card__description">Mis Cuotas permite comprar aquello que quieres, cuando quieres, aumentando el ticket medio hasta en 40%.</p>
            <hr />
            <div className="subtitle__image">
              <h3 className="comercios-card__subtitle">
                Hasta <span className="numero1">{segundonumero}%</span>
              </h3>
              <img src="img/para_comercios/card-2.png" alt="" />
            </div>
            </div>
            <div className="comercios-card__info atras">
              <h3 className="comercios-card__title">Incrementa tu Ticket <br /> Promedio</h3>
              <p>Mis Cuotas permite comprar aquello que quieres, cuando quieres, aumentando el ticket medio hasta en 40%.</p>
              <hr />
            </div>
            </div>
          </div>
          <div className="comercios-card">
              <div className="tarjeta">
                <div className="adelante">
            <h3 className="comercios-card__title">Atrae nuevos <br /> clientes</h3>
            <p className="comercios-card__description">Los comercios informan que hasta el 30% de los clientes de Mis Cuotas son nuevos en su negocio</p>
            <hr />
            <div className="subtitle__image">
              <h3 className="comercios-card__subtitle">
                Hasta <span>0{terceronumero}%</span>
              </h3>
              <img src="img/para_comercios/card-3.png" alt="" />
            </div>
            </div>
            <div className="comercios-card__info atras">
              <h3 className="comercios-card__title">Atrae nuevos <br /> clientes</h3>
              <p>Los comercios informan que hasta el 30% de los clientes de Mis Cuotas son nuevos en su negocio</p>
              <hr />
            </div>
            </div>
          </div>
        </div>
        <a  className="hero-comercios__btn" >Más Información</a>
        <p className="hero-comercios__paragraph">*Estos valores son estimados en base al comportamiento de venta de nuestros comercios aliados. Estas cifras son referenciales porque pueden variar en función del comercio. </p>
      </div>
    </section>
  )
}