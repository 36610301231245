import ReactOwlCarousel from "react-owl-carousel"


export function SectionTiendasDestacadas () {
  const options = {
    stagePadding: 1,
    loop:true,
    margin:15,
    nav:false,
    navText: ["<i class=\"fa fa-angle-left\" aria-hidden=\"true\"></i>","<i class=\"fa fa-angle-right\" aria-hidden=\"true\"></i>"],
    dots: true,
    autoplay:true,
    autoplayTimeout:3000,
    autoplayHoverPause:true,
    responsive:{
      0:{
        items:1
      },
      600:{
        items:2
      },
      1200:{
        items:3
      }
    }
  
  }

  return (
    <section className="section section-tiendas-destacadas">
      <div className="container">
        <h2 className="title">Tiendas destacadas</h2>
        <article className="tiendas-destacadas-movil">
          <ReactOwlCarousel {...options} className="owl-carousel owl-theme">
          <div className="tienda-destacada">
              <div className="tiendaImagenes">
                <div className="logoFlotanteTienda">
                  {/* <!--<img src="img/tiendas_aliadas/sansungLogo.png">--> */}
                </div>
                <img src="img/tiendas_aliadas/HONOR-MEGAPLAZA.jpg" alt="" />
              </div>
              <a href="https://www.honorperu.pe/nuestras-tiendas" target="_blank" rel="noreferrer">
                Ver más
                <i className="fas fa-arrow-right"></i>
              </a>
            </div>
            <div className="tienda-destacada">
              <div className="tiendaImagenes">
                <div className="logoFlotanteTienda">
                {/* <!-- <img src="img/tiendas_aliadas/appleLogo.png">--> */}
                </div>
                <img src="img/tiendas_aliadas/HUAWEI-AREQUIPA.jpg" alt="" />
              </div>
              <a href="https://consumer.huawei.com/pe/retail/" target="_blank" rel="noreferrer">
                Ver más
                <i className="fas fa-arrow-right"></i>
              </a>
            </div>
            <div className="tienda-destacada">
              <div className="tiendaImagenes">
                <div className="logoFlotanteTienda">
                  {/* <!--<img src="img/tiendas_aliadas/logoLg.png">--> */}
                </div>
                <img src="img/tiendas_aliadas/MISTORE-BELLAVISTA.jpg" alt="" />
              </div>
              <a href="https://xiaomiperu.com/nuestras-tiendas" target="_blank" rel="noreferrer">
                Ver más
                <i className="fas fa-arrow-right"></i>
              </a>
            </div>
            <div className="tienda-destacada">
              <div className="tiendaImagenes">
                <div className="logoFlotanteTienda">
                  {/* <!--<img src="img/tiendas_aliadas/huaweiLogo.png">--> */}
                </div>
                <img src="img/tiendas_aliadas/TEC-BELLAVISTA.jpg" alt="" />
              </div>
              <a href="https://tecstore.pe/nuestras-tiendas" target="_blank" rel="noreferrer">
                Ver más
                <i className="fas fa-arrow-right"></i>
              </a>
            </div>
            <div className="tienda-destacada">
              <div className="tiendaImagenes">
                <div className="logoFlotanteTienda">
                  {/* <!--<img src="img/tiendas_aliadas/sansungLogo.png">--> */}
                </div>
                <img src="img/tiendas_aliadas/DJI.jpg" alt="" />
              </div>
              <a href="https://dji.pe/" target="_blank" rel="noreferrer">
                Ver más
                <i className="fas fa-arrow-right"></i>
              </a>
            </div>
            <div className="tienda-destacada">
              <div className="tiendaImagenes">
                <div className="logoFlotanteTienda">
                  {/* <!--<img src="img/tiendas_aliadas/appleLogo.png">--> */}
                </div>
                <img src="img/tiendas_aliadas/ZTE.jpg" alt="" />
              </div>
              <a  target="_blank" rel="noreferrer">
                Ver más
                <i className="fas fa-arrow-right"></i>
              </a>
            </div>
          </ReactOwlCarousel>
        </article>
        {/* 
        <article className="tiendas-destacadas tiendas-destacadas-destok" >
          <div className="tienda-destacada">
            <div className="tiendaImagenes">
              <div className="logoFlotanteTienda">

              </div>
              <img src="img/tiendas_aliadas/HONOR-MEGAPLAZA.jpg" alt="" />
            </div>
            <a href="https://www.honorperu.pe/nuestras-tiendas" target="_blank" rel="noreferrer">
              Ver más
              <i className="fas fa-arrow-right"></i>
            </a>
          </div>
          <div className="tienda-destacada">
            <div className="tiendaImagenes">
              <div className="logoFlotanteTienda">

              </div>
              <img src="img/tiendas_aliadas/HUAWEI-AREQUIPA.jpg" alt="" />
            </div>
            <a href="https://consumer.huawei.com/pe/retail/" target="_blank" rel="noreferrer">
              Ver más
              <i className="fas fa-arrow-right"></i>
            </a>
          </div>
          <div className="tienda-destacada">
            <div className="tiendaImagenes">
              <div className="logoFlotanteTienda">

              </div>
              <img src="img/tiendas_aliadas/MISTORE-BELLAVISTA.jpg" alt="" />
            </div>
            <a href="https://xiaomiperu.com/nuestras-tiendas" target="_blank" rel="noreferrer">
              Ver más
              <i className="fas fa-arrow-right"></i>
            </a>
          </div>
          <div className="tienda-destacada">
            <div className="tiendaImagenes">
              <div className="logoFlotanteTienda">

              </div>
              <img src="img/tiendas_aliadas/TEC-BELLAVISTA.jpg" alt="" />
            </div>
            <a href="https://tecstore.pe/nuestras-tiendas" target="_blank" rel="noreferrer">
              Ver más
              <i className="fas fa-arrow-right"></i>
            </a>
          </div>
          <div className="tienda-destacada">
            <div className="tiendaImagenes">
              <div className="logoFlotanteTienda">

              </div>
              <img src="img/tiendas_aliadas/HUAWEI-COMAS.jpg" alt="" />
            </div>
            <a href="https://consumer.huawei.com/pe/retail/" target="_blank" rel="noreferrer">
              Ver más
              <i className="fas fa-arrow-right"></i>
            </a>
          </div>
          <div className="tienda-destacada">
            <div className="tiendaImagenes">
              <div className="logoFlotanteTienda">

              </div>
              <img src="img/tiendas_aliadas/MISTORE-SANMIGUEL.jpg" alt="" />
            </div>
            <a href="https://xiaomiperu.com/nuestras-tiendas" target="_blank" rel="noreferrer">
              Ver más
              <i className="fas fa-arrow-right"></i>
            </a>
          </div>
        </article>
      */}
      </div>
    </section>
  )
}