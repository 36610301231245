
import Select from 'react-select'
import { useState,useEffect } from "react";
import axios from 'axios'


export function SectionListTiendas () {
  

  const optionsTiendas = [
    { value: 'HONOR', label: 'HONOR' },
    { value: 'TECSTORE', label: 'TECSTORE' },
    { value: 'MISTORE', label: 'MISTORE' },
    { value: 'HUAWEI', label: 'HUAWEI' }
  ]
  const optionsDis = [
    { value: 'Otros', label: 'Otros Distritos' },
    { value: 'Lima', label: 'Lima' }
  ]

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? '#000081' : '#A9A9A9',
    }),
 
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';
  
      return { ...provided, opacity, transition };
    }
  }

  const [token,setToken] =useState("");

  const [tiendas,setTiendas] =useState("");

  const [selectMarca, cambiarSelectMarca] = useState('')
  const handleSelectMarca =(e) =>{
    cambiarSelectMarca(e.value);
  }
  const [selectUbicacion, cambiarSelectUbicacion] = useState('')
  const handleSelectUbicacion =(e) =>{
    cambiarSelectUbicacion(e.value);
  }  

  useEffect(() => {
    const data2 = {
        usuario: "MISCUOTASWeb",
        clave: "12345678",
        tipo: "Web",
        EmpresaId: "10717122938"
      };

    axios.post(`https://miscuotas.com:8901/api`,  data2 )
      .then(res => {
        const token2=res.data.access_token
        setToken(token2);
        const data3={
          Marca:'',
          Direccion:''
          }
        const headers1 = {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+token2
        }
  
        axios.post(`https://miscuotas.com:8902/api1`,  data3 ,{headers:headers1})
        .then(res => {
          const tiendas=res.data.lstMCUTiendas;
          setTiendas(tiendas)
        })
      })

  }, [])

  const handleSearch = event => {
    event.preventDefault();
    
    const data3={
      Marca:selectMarca,
      Direccion:selectUbicacion
    }
    const headers1 = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+token
      }
    axios.post(`https://miscuotas.com:8902/api1`,  data3 ,{headers:headers1})
      .then(res => {
        const tiendas=res.data.lstMCUTiendas;
        setTiendas(tiendas)
      })
  }

  
  return (
    <section className="section section-tiendas-productos">
      <div className="container">
        <h2 className="title">Lista De Tiendas</h2>
        <div className='row'>
        <Select options={optionsTiendas} className="selectOption col-md-4 col-6"  styles={customStyles}  onChange={handleSelectMarca}/>
        <Select options={optionsDis} className="selectOption col-md-4 col-6" styles={customStyles}  onChange={handleSelectUbicacion}/>
        <button  className="buttonBuscar col-md-4 col-6" onClick={handleSearch}> Buscar </button>
        </div>
        <section className='mod-listado-tiendas'>
          <ul className='listtiendas'>
            {tiendas !== "" ?(
                tiendas.map(tienda => (
                  <li className='unaTienda'><a href={tienda.url} target="_blank"><b className='marcaTienda'>{tienda.marca}</b> | <b>{tienda.ubicacion}</b> </a></li>
                ))
              ) : <li className='unaTienda'>No hay tiendas</li>
            }

          </ul>
        </section>
      </div>
    </section>
  )
}