import { SectionTiendasPasos } from "../components/SectionTiendasPasos/SectionTiendasPasos" 
import React from "react";
import { SectionTiendasDetails } from "../components/SectionTiendasDetails/SectionTiendasDetails";
class ScrollToTopOnMount extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return null;
  }
}
export function PagosTiendas () {
  return <>
  <ScrollToTopOnMount />
    <SectionTiendasPasos />
    <SectionTiendasDetails />
  </>
}